<template>
  <div class="d-flex flex-row align-stretch mb-8 avatar-wrapper">
    <div>
      <v-skeleton-loader
        tile
        width="160"
        height="160"
        type="card-avatar"
        v-if="imageLoading"
      ></v-skeleton-loader>
      <v-avatar v-else size="160" tile>
        <img :src="thumbSrc" />
      </v-avatar>
    </div>

    <div class="d-flex flex-column justify-space-around ml-6">
      <v-btn
        text
        class="text-capitalize font-weight-bold text-light-blue"
        @click="showCropperModal = !showCropperModal"
        :loading="imageLoading"
      >
        <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />Upload Image
      </v-btn>
      <v-btn
        v-if="this.image"
        text
        class="text-capitalize font-weight-bold text-light-grey"
        @click="deleteConfirmDialog = true"
        :loading="imageLoading"
      >
        <img src="@/assets/icons/icon-trash.svg" class="mr-2" alt /> Delete
        Image
      </v-btn>
    </div>

    <image-cropper v-model="showCropperModal" @change="imageChange" />

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Remove Image"
      message="Are you sure you want to remove the image?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteImage"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mdiImagePlus, mdiDelete } from '@mdi/js'
import ImageCropper from '@/components/modals/ImageCropper'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  props: {
    image: null,
    imageLoading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ImageCropper,
    ConfirmModal
  },

  computed: {
    thumbSrc() {
      return this.image
        ? this.image.url
        : require('@/assets/images/default-image.jpeg')
    }
  },

  data() {
    return {
      showCropperModal: false,
      deleteConfirmDialog: false,
      icons: {
        addImage: mdiImagePlus,
        delete: mdiDelete
      },
      snackbar: {
        show: false,
        message: null
      }
    }
  },

  methods: {
    imageChange(image) {
      this.$emit('change', image)
    },

    deleteImage() {
      this.deleteConfirmDialog = false
      this.$emit('removed')
    }
  }
}
</script>
