<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'questionnaires.reassessment' }"
          >Reassessment Questionnaire</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
        <v-icon class="mr-3">
          {{ icons.delete }}
        </v-icon>
        Delete Reassessment Questionnaire
      </v-btn>
    </v-app-bar>

    <v-divider></v-divider>

    <v-row>
      <v-col sm="12">
        <div class="pa-8">
          <v-form v-if="form" @submit.prevent="saveQuestionnaire" ref="form">
            <div class="d-flex justify-space-between align-center mb-8">
              <h2 class="mr-auto">{{ displayName() }}</h2>
              <v-btn
                type="submit"
                color="primary"
                width="150px"
                :loading="form.$busy"
                depressed
              >
                Save
              </v-btn>
            </div>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  required
                  label="Title *"
                  hide-details="auto"
                  v-model="form.title"
                  :error-messages="form.$getError('title')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="4">
                <v-text-field
                  outlined
                  required
                  label="Label *"
                  hide-details="auto"
                  v-model="form.label"
                  :error-messages="form.$getError('label')"
                ></v-text-field>
              </v-col>
              <v-col sm="12" lg="4">
                <v-text-field
                  outlined
                  label="Note"
                  hide-details="auto"
                  v-model="form.note"
                  :error-messages="form.$getError('note')"
                ></v-text-field>
              </v-col>
              <v-col sm="12" lg="4">
                <v-select
                  :items="questionTypes"
                  outlined
                  required
                  label="Question Type"
                  hide-details="auto"
                  v-model="form.question_type"
                  :error-messages="form.$getError('question_type')"
                  item-text="label"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="6">
                <v-text-field
                  outlined
                  required
                  label="Sub Text"
                  hide-details="auto"
                  v-model="form.sub_text"
                  :error-messages="form.$getError('sub_text')"
                ></v-text-field>
              </v-col>
              <v-col sm="12" lg="6">
                <v-text-field
                  outlined
                  label="Sub Question"
                  hide-details="auto"
                  v-model="form.sub_question"
                  :error-messages="form.$getError('sub_question')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="8" sm="12">
                <v-row>
                  <v-col sm="12" style="margin-bottom: -20px">
                    <v-checkbox v-model="form.is_custom_screen_next">
                      <template v-slot:label>
                        <h4>Custom Screen Next</h4>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col sm="12">
                    <image-upload
                      :image="form.custom_screen_next_image"
                      @change="imageChange"
                      @removed="imageRemoved"
                      :imageLoading="imageLoading"
                    />
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      label="Title"
                      hide-details="auto"
                      v-model="form.custom_screen_next.title"
                      :error-messages="
                        form.$getError('custom_screen_next.title')
                      "
                      :disabled="!form.is_custom_screen_next || imageLoading"
                      :loading="imageLoading"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      label="Description"
                      hide-details="auto"
                      v-model="form.custom_screen_next.description"
                      :error-messages="
                        form.$getError('custom_screen_next.description')
                      "
                      :disabled="!form.is_custom_screen_next || imageLoading"
                      :loading="imageLoading"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      label="Button Label"
                      hide-details="auto"
                      v-model="form.custom_screen_next.button_label"
                      :error-messages="
                        form.$getError('custom_screen_next.button_label')
                      "
                      :disabled="!form.is_custom_screen_next || imageLoading"
                      :loading="imageLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div
                  class="d-flex justify-space-between align-center mb-8 mt-6"
                >
                  <div class="mr-auto">
                    <h4>
                      <v-progress-circular
                        v-if="draggableStatus"
                        indeterminate
                        color="primary"
                        size="20"
                        width="3"
                        class="mr-1"
                      ></v-progress-circular>
                      Choices
                    </h4>
                    <span class="caption">
                      You can rearrange the card sequence by clicking and
                      dragging it.
                    </span>
                  </div>
                  <v-btn
                    small
                    color="primary"
                    width="150px"
                    @click="addChoiceDialog = true"
                    depressed
                  >
                    <v-icon left> {{ icons.add }}</v-icon> Add Choice
                  </v-btn>
                </div>
                <draggable
                  v-model="form.choices"
                  class="row"
                  @change="sortChoices"
                  :class="{ draggableStatus }"
                >
                  <template v-for="choice in form.choices">
                    <v-list-item :key="choice.order_column">
                      <v-list-item-icon>
                        <v-chip class="ma-2" color="primary">
                          {{ choice.order_column }}
                        </v-chip>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="choice.choice"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :loading="loading"
                          icon
                          @click="
                            deleteChoiceConfirmDialog = true
                            deleteChoiceId = choice.id
                          "
                          :disabled="deleteChoiceLoading"
                        >
                          <v-icon color="red lighten-1">{{
                            icons.delete
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </draggable>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
    </v-row>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <AddChoiceDialog
      v-model="addChoiceDialog"
      :questionnaire="this.reassessmentQuestionnaire"
    ></AddChoiceDialog>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete questionnaire"
      message="Are you sure you want to delete this reassessment questionnaire?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteQuestionnaire"
    ></ConfirmModal>

    <ConfirmModal
      v-model="deleteChoiceConfirmDialog"
      title="Delete Choice"
      message="Are you sure you want to delete this choice?"
      @cancel="deleteChoiceConfirmDialog = false"
      @confirm="deleteChoice"
      :confirmLoading="deleteChoiceLoading"
    ></ConfirmModal>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUpload from '@/components/ImageUpload'
import { mdiChevronLeft, mdiContentSave, mdiTrashCan, mdiPlus } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@/utils/form'
import AddChoiceDialog from './components/AddChoiceDialog.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
// import PreviewExercise from './components/PreviewExercise.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ReassessmentDetails',
  components: {
    AppBarNavIcon,
    ImageUpload,
    AddChoiceDialog,
    ConfirmModal,
    draggable
  },
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        save: mdiContentSave,
        delete: mdiTrashCan,
        add: mdiPlus
      },
      form: null,
      loading: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      rules: {
        required: [value => String(value).length > 0 || 'Required']
      },
      statuses: [
        { text: 'Active', value: 1 },
        { text: 'Disabled', value: 0 }
      ],
      sortLoading: false,
      deleteChoiceConfirmDialog: false,
      deleteChoiceLoading: false,
      deleteChoiceId: null,
      addChoiceDialog: false,
      deleteConfirmDialog: false,
      deleteLoading: false,
      questionTypes: [
        {
          label: 'Multiple Choice',
          value: 'multiple_choice'
        },
        {
          label: 'Single Choice',
          value: 'single_choice'
        },
        {
          label: 'Identification',
          value: 'identification'
        },
        {
          label: 'Multiple Choice with Open Ended',
          value: 'multiple_choice_open_ended'
        },
        {
          label: 'Time Period',
          value: 'time_period'
        }
      ],
      imageLoading: false
    }
  },
  computed: {
    ...mapState({
      reassessmentQuestionnaire: state =>
        state.reassessment.reassessmentQuestionnaireDetails
    }),
    draggableStatus() {
      return this.sortLoading
    }
  },
  methods: {
    ...mapActions({
      getReassessmentQuestionnaire:
        'reassessment/getReassessmentQuestionnaireDetails',
      sortChoicesAction: 'reassessment/sortChoices',
      destroyChoice: 'reassessment/deleteReassessmentQuestionnaireChoice',
      destroyQuestionnaire: 'reassessment/deleteReassessmentQuestionnaire',
      updateQuestionnaire:
        'reassessment/updateReassessmentQuestionnaireDetails',
      changeImage: 'reassessment/changeImage',
      removeImage: 'reassessment/removeImage'
    }),

    ...mapMutations({
      clearReassessmentQuestionnaireDetails:
        'reassessment/clearReassessmentQuestionnaireDetails'
    }),

    async sortChoices() {
      this.sortLoading = true

      const data = {
        id: this.$route.params.id,
        choices: this.form.choices.map(step => step.id)
      }

      this.sortChoicesAction(data)
        .then(_ => {})
        .catch(_ => {
          this.showSnackbar('Something went wrong when sorting', 'danger')
        })
        .finally(_ => {
          this.sortLoading = false
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    async deleteChoice() {
      this.deleteChoiceLoading = true

      const data = {
        id: this.$route.params.id,
        choiceId: this.deleteChoiceId
      }

      this.destroyChoice(data)
        .then(async () => {
          await this.getReassessmentQuestionnaire(this.$route.params.id)
          this.showSnackbar('Choice has been deleted', 'success')
        })
        .catch(_ => {
          this.showSnackbar(
            'Something went wrong when deleting choice',
            'danger'
          )
        })
        .finally(_ => {
          this.deleteChoiceLoading = false
          this.deleteChoiceConfirmDialog = false
        })
    },

    async deleteQuestionnaire() {
      this.deleteConfirmDialog = false
      this.loading = true
      await this.destroyQuestionnaire(this.$route.params.id)
      this.loading = false
      this.$router.replace('/questionnaires/reassessment')
    },

    displayName() {
      return this.form.title || 'Reassessment Details'
    },

    async saveQuestionnaire() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateQuestionnaire(this.form.$data())
        .then(() => {
          this.showSnackbar(
            'Reassessment Questionnaire successfully updated!',
            'success'
          )
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    async imageChange(image) {
      this.imageLoading = true
      let data = new FormData()
      data.append('id', this.$route.params.id)
      data.append('image', image)
      await this.changeImage(data)
      this.imageLoading = false
      this.showSnackbar(
        'Custom Screen Next image successfully updated!',
        'success'
      )
    },

    async imageRemoved() {
      this.reassessmentQuestionnaire.custom_screen_next_image = null
      this.removeImage(this.$route.params.id)
    },

    thumbnailSrc(image) {
      return image ? image.url : require('@/assets/images/default-image.jpeg')
    }
  },
  async created() {
    this.loading = true
    await this.getReassessmentQuestionnaire(this.$route.params.id)
    this.loading = false
  },
  destroyed() {
    this.clearReassessmentQuestionnaireDetails()
  },
  watch: {
    reassessmentQuestionnaire: {
      deep: true,
      handler() {
        this.form = new Form(
          JSON.parse(JSON.stringify(this.reassessmentQuestionnaire))
        )
      }
    }
  }
}
</script>

<style lang="css" scoped>
.floating {
  position: absolute;
  top: -10px;
  right: -7px;
}

.floatingCounter {
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -13px !important;
}

.row.draggableStatus {
  pointer-events: none;
}
</style>
