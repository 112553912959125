<template>
  <v-dialog v-model="show" scrollable persistent max-width="500">
    <v-card>
      <v-card-title class="headline">Upload Image</v-card-title>
      <v-card-text>
        <div :class="{ 'rounded-cropper': rounded }" v-if="imgSrc">
          <vue-cropper
            ref="cropper"
            :guides="true"
            drag-mode="crop"
            :src="imgSrc"
            :aspect-ratio="aspectRatio"
            :initial-aspect-ratio="aspectRatio"
            :view-mode="1"
          />
        </div>
        <div
          v-else
          class="grey lighten-1 pa-12 ma-6 clickable"
          @click="launchFilePicker"
        >
          <h1 class="text-center pa-12 white--text">SELECT IMAGE</h1>
          <input
            type="file"
            ref="filePicker"
            @change="onFileChange"
            style="display:none"
            accept="image/*"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text @click="show = false">Cancel</v-btn>
        <v-btn color="primary" v-if="this.imgSrc" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: this.value,
      imgSrc: null
    }
  },

  methods: {
    launchFilePicker() {
      this.$refs.filePicker.click()
    },

    onFileChange(event) {
      const file = event.target.files[0]
      if (file && this.isImage(file)) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.imgSrc = e.target.result
        }
      }
    },

    save() {
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.$emit('change', blob)
        this.show = false
        this.imgSrc = null
      })
    },

    isImage(file) {
      return file && file['type'].split('/')[0] === 'image'
    }
  },

  watch: {
    value(val) {
      this.show = val
    },

    show(val) {
      this.$emit('input', val)
      this.imgSrc = null
    }
  }
}
</script>

<style scoped>
.rounded-cropper >>> .cropper-view-box,
.rounded-cropper >>> .cropper-face {
  border-radius: 50%;
}
</style>
